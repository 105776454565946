<template>
  <div class="home">
    <div class="hero-title">
      <h1>KRUTRIMA ART</h1>
      <h3>where AI meets ART</h3>
      <br />
      <div class="search-bar">
        <span class="material-icons">search</span>
        <input
          class="search-container"
          placeholder="Search"
          type="text"
          name=""
          value=""
          v-model="searchQuery"
          @blur="searchEnter = false"
        />
        <!-- <p v-if="searchQuery" style="font-size: 8px;">press enter</p> -->
      </div>
    </div>
    <ArtPreview v-show="this.$store.state.blogPhotoPreview" />
    <div class="art-card-container" v-if="this.searchQuery.length === 0">
      <div
          v-for="(post, index) in blogPostCards"
          :key="index"
        >
          <div class="art-new-container" @click="openPreview">
            <BlogCard :post="post" class="art-card" />
          </div>
        </div>
    </div>
    <div class="art-card-container" v-if="this.searchQuery.length > 0">
      <div
          v-for="(post, index) in blogSearchPostCards"
          :key="index"
        >
          <div class="art-new-container" @click="openPreview">
            <BlogCard :post="post" class="art-card" />
          </div>
        </div>
    </div>
    <div ref="loadMoreObserver" class="loadmore-container">
      <p>{{ this.displayMsg }}</p>
    </div>
  </div>
</template>

<script>
// import BlogCard from "../components/BlogCard.vue";
import ArtPreview from "../components/ArtPreview.vue";
import BlogCard from "../components/ArtCard.vue";
export default {
  name: "KrutrimaHome",
  components: { BlogCard, ArtPreview },
  data() {
    return {
      searchQuery: "",
      scrollEndCount: 0,
      displayMsg: "loading more arts...",
    };
  },
  created() {
    window.onbeforeunload = () => {
      localStorage.clear();
    }
  },
  methods: {
    viewMoreBlogs() {
      this.$router.push({ name: "Blogs" });
    },
    openPreview() {
      console.log("open art")
      this.$store.commit("openPhotoPreview");
      this.$store.newOpen = true;
    },
    async fetchMorePosts() {
      console.log("fetching more posts");
      await this.$store.dispatch("getPost");
    },
    async searchPosts(){
      await this.$store.dispatch("getSearchPost", this.searchQuery);
    }
  },
  computed: {
    blogPostCards() {
      const storedBlogPostCards = JSON.parse(
        localStorage.getItem("blogPostCards") || "[]"
      );
      return this.$store.state.blogPosts.length > storedBlogPostCards.length
        ? this.$store.state.blogPosts
        : storedBlogPostCards;
    },
    blogSearchPostCards(){
      return this.$store.state.searchPosts;
    }
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.$store.state.blogPosts.length > 0) {
          this.fetchMorePosts();
        }else if(this.scrollEndCount >= 1){
          this.displayMsg = "end of arts... publish more..!!";
        }else{
          this.scrollEndCount += 1;
        }
      });
    }, options);

    this.observer.observe(this.$refs.loadMoreObserver);
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  watch: {
    blogPostCards: {
      deep: true,
      handler(newVal) {
        localStorage.setItem("blogPostCards", JSON.stringify(newVal));
      },
    },
    searchQuery(newVal){
      if (newVal.length > 1){
        this.searchPosts()
        this.displayMsg = "No More Arts"
      }
    },
    blogSearchPostCards:{
      handler(newVal){
        if (newVal.length === 0){
          this.displayMsg = "No Arts with Search term"
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.hero-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;

  h1 {
    text-align: center;
    font-weight: 900;
    font-size: 44px;
    color: #163856;
  }

  h3 {
    font-family: "PT Mono", PT Mono, monospace;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .search-bar {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .material-icons {
      position: absolute;
      margin-top: 3px;
      margin-left: 10px;
    }

    .search-container {
      width: calc(60vw);
      height: calc(2vw);
      border-radius: 50px;
      border: None;
      font-family: "PT Mono", PT Mono, monospace;
      font-size: 16px;
      font-weight: bold;
      background-color: #e9e9e9;
      // padding: 3px, 10px, 3px;
      padding-left: 40px;

      &:hover {
        background-color: #d1cfcf;
      }
    }
  }
}

.art-card-container{
  padding: 1vw;
  width: 100%;
  margin: 0px auto;
  columns: 5;
  background-color: #f2f8f8;
  // column-gap: 10px;

  .art-new-container{
    // margin: 5px 0px;
    margin-bottom: 10px;
    width: calc(19vw);
    break-inside: avoid;
  }
}

.loadmore-container{
  display:flex;
  text-align: center;
  height: 5vw;
  justify-content: center;
  align-items: center;
  background-color: #f2f8f8;
}

.art-card-wrap {
  padding: 1vw;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f2f8f8;

  .art-sub-columns {
    width: calc(19.36vw);

    .art-dummy-container {
      width: 100%;

      .art-container {
        width: 100%;
        padding: 2px;

        .art-card {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
