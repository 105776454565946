<template>
  <div>
    <!-- <div class="hero">
      <h1>Your Art</h1>
    </div> -->
    <div>
      <br />
      <br />
    </div>
    <div class="empty_banner" v-if="noArts">
      <div style="color: red; font-weight: bold; margin-bottom: 25px;">You have no arts published</div>
      <div class="empty-message">
        Your creativity knows no bounds! Publish your AI-generated art now
      </div>
      <router-link class="router-button" :to="{ name: 'CreatePost' }"
        >publish art</router-link
      >
    </div>
    <ArtPreview v-show="this.$store.state.blogPhotoPreview" />
    <div class="art-card-container" v-if="!noArts">
      <div v-for="(post, index) in yourBlogPostCards" :key="index">
        <div class="art-new-container" @click="openPreview">
          <BlogCard :post="post" class="art-card" />
        </div>
      </div>
    </div>
    <div ref="yourLoadMoreObserver" v-if="!noArts" class="loadmore-container">
      <p>{{ displayMsg }}</p>
    </div>
  </div>
</template>

<script>
import ArtPreview from "../components/ArtPreview.vue";
import BlogCard from "../components/ArtCard.vue";
export default {
  name: "UserBlogs",
  components: { ArtPreview, BlogCard },

  data() {
    return {
      noArts: false,
      scrollEndCount: 0,
      displayMsg: "loading your arts...",
    };
  },

  created() {
    window.onbeforeunload = () => {
      localStorage.clear();
    };
  },

  computed: {
    yourBlogPostCards() {
      const storedBlogPostCards = JSON.parse(
        localStorage.getItem("yourBlogPostCards") || "[]"
      );
      return this.$store.state.yourBlogPosts.length > storedBlogPostCards.length
        ? this.$store.state.yourBlogPosts
        : storedBlogPostCards;
    },
  },

  methods: {
    openPreview() {
      this.$store.commit("openPhotoPreview");
    },
    async fetchMoreYourPosts() {
      console.log("fetching more your posts");
      await this.$store.dispatch("YourPost");
      if (this.$store.state.yourBlogPosts.length == 0){
        this.noArts = true;
      }
    },
    artAvailable(){
      // this.fetchMoreYourPosts();
      console.log(this.yourBlogPostCards.length)
      console.log(this.$store.state.yourBlogPosts.length)
      if (this.yourBlogPostCards.length==0){
        this.noArts = true;
      }else{
        this.noArts = false;
      }
    }
  },

  beforeDestroy() {
    if (!this.noArts) {
      this.observer.disconnect();
    }
  },

  mounted() {

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    };
    if (!this.noArts) {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.fetchMoreYourPosts();
          } else if (this.scrollEndCount >= 1) {
            this.displayMsg = "end of your arts... publish more..!!";
          } else {
            this.scrollEndCount += 1;
          }
        });
      }, options);

      this.observer.observe(this.$refs.yourLoadMoreObserver);
    }
  },

  watch: {
    yourBlogPostCards: {
      deep: true,
      handler(newVal) {
        localStorage.setItem("yourBlogPostCards", JSON.stringify(newVal));
        // this.noArts = newVal.length === 0;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.empty_banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // .empty-message {
  //   margin-bottom: 10px;
  // }
}
.art-card-container {
  padding: 1vw;
  width: 100%;
  margin: 0px auto;
  columns: 5;
  background-color: #f2f8f8;
  // column-gap: 10px;

  .art-new-container {
    // margin: 5px 0px;
    margin-bottom: 10px;
    width: calc(19vw);
    break-inside: avoid;
  }
}

.loadmore-container {
  display: flex;
  text-align: center;
  height: 5vw;
  justify-content: center;
  align-items: center;
  background-color: #f2f8f8;
}
.art-card-wrap {
  padding: 1vw;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f2f8f8;

  .art-sub-columns {
    width: calc(19.36vw);

    .art-dummy-container {
      width: 100%;

      .art-container {
        width: 100%;
        padding: 2px;

        .art-card {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .toggle-edit {
    display: flex;
    align-items: center;
    position: absolute;
    top: -80px;
    right: 0;

    span {
      margin-right: 16px;
      font-weight: 600;
    }

    input[type="checkbox"] {
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 50px;
      height: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      top: 0;
      left: 0;
      background: #163856;
      transform: scale(1.1);
      transition: 750ms ease all;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    input:checked[type="checkbox"]:before {
      background: #fff;
      left: 32px;
    }
  }
}
</style>