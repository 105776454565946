<template>
  <div class="modal" @click="closePreview">
    <div v-if="this.showDelete" class="overlay">
      <div class="delete-container">
        <div class="loading-message">art will be deleted forever</div>
        <button
          href="#"
          class="art-button art-button-red"
          @click="deletePost"
          style="margin-top: 14px"
        >
          <span class="material-icons"> delete_forever </span>
          <span class="button-text">delete</span>
        </button>
      </div>
    </div>
    <div class="share-art-container" @click="closePreview">
      <div class="art-prompt-container">
        <div class="art-title-container">
          <h2>{{ this.blogTitle }}</h2>
        </div>
        <div class="modal-prompt-container">
          <div class="prompt-header">
            <h3>PROMPT</h3>
            <div class="art-copy-prompt-button">
              <button
                class="copy-button"
                :class="this.isCopiedVar ? 'pressed-button' : ''"
                @click="copyPrompt"
                type="button"
              >
                <span class="material-icons">content_copy</span>
                <span v-if="!this.isCopiedVar">copy prompt</span>
                <span v-if="this.isCopiedVar">copied</span>
              </button>
            </div>
          </div>

          <hr />
          <textarea
            readonly
            id="prompt-text"
            class="prompt-box"
            rows="5"
            cols="50"
            v-bind:placeholder="this.blogHtml"
          />
          <!-- <h4>{{ this.blogTitle }}</h4> -->
        </div>
        <div class="modal-md-container">
          <dl>
            <dt>Model</dt>
            <dd>
              <div class="art-model-container">
                <dd>{{ artModel }}</dd>
              </div>
            </dd>
            <br />
            <dt>Created by</dt>
            <dd>{{ blogUserName }}</dd>
          </dl>
        </div>
        <div class="dummy-container"></div>
        <div class="art-buttons">
          <button
            href="#"
            class="art-button art-button-red"
            v-if="showDeleteIcon"
            @click="showDeletePopup"
          >
            <span class="material-icons"> delete_forever </span>
          </button>
          <!-- <div v-if="showDeleteIcon && this.deleteMsg">deleting forever...</div> -->
          <div v-if="!showDeleteIcon" class="dummy_block"></div>
          <div class="art-md-buttons">
            <button
              :href="this.blogCoverPhoto"
              class="art-button"
              download
              target="_blank"
              @click="downloadArt"
            >
              <span class="button-text">{{ getDownloadCount() }}</span>
              <span class="material-icons"> file_download </span>
            </button>
            <button
              href="#"
              class="art-button"
              :class="likeButtonState ? 'pressed-button' : ''"
              @click="likeArt"
            >
              <span class="button-text">{{ getLikeCount() }}</span>
              <span class="material-icons" v-if="!likeButtonState">
                favorite_border
              </span>
              <span class="material-icons" v-if="likeButtonState">
                favorite
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="art-container">
        <div class="art-image">
          <img :src="this.blogCoverPhoto" alt="Uploaded Image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import close from "../assets/Icons/times-circle-light.svg";
export default {
  // components:{
  //     close,
  // },
  data() {
    return {
      likeCount: "",
      downloadCount: "",
      isCopiedVar: false,
      likeButtonState: false,
      deleteMsg: false,
      showDelete: false,
    };
  },
  methods: {
    closePreview() {
      this.$store.commit("openPhotoPreview");
    },

    copyPrompt() {
      // Get the textarea element
      const textarea = document.getElementById("prompt-text");

      // Get the placeholder text
      const placeholder = textarea.getAttribute("placeholder");

      // Create a temporary textarea element
      const tempTextarea = document.createElement("textarea");

      // Set its value to the placeholder text
      tempTextarea.value = placeholder;

      // Append the temporary textarea to the document
      document.body.appendChild(tempTextarea);

      // Select the text in the temporary textarea
      tempTextarea.select();

      // Copy the selected text to the clipboard
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(tempTextarea);

      // setting state to change button in ui
      // this.$store.copyButtonState = !this.$store.copyButtonState;
      // if (this.$store.copyButtonState){
      //   this.isCopiedVar = true;
      // }else{
      //   this.isCopiedVar = false;
      // }
      this.isCopiedVar = !this.isCopiedVar;
    },

    likeArt() {
      this.likeButtonState = !this.likeButtonState;
      this.$store.dispatch("updateLikeCount");
    },

    // async downloadImage(url) {
    //   var base64 = await axios
    //     .get(url, {
    //       responseType: "arraybuffer",
    //     })
    //     .then((response) =>
    //       Buffer.from(response.data, "binary").toString("base64")
    //     )
    //     .catch((error) => console.log(error));
    //   var img = new Image();
    //   img.src = "data:image/jpeg;base64, " + base64;
    //   return img;
    // },

    async downloadArt() {
      // this.likeButtonState = !this.likeButtonState;
      this.$store.dispatch("updateDownloadCount");

      // Create a new a element
      const link = document.createElement("a");
      // Set the href and download attributes
      link.href = this.blogCoverPhoto;
      link.download = "blog-cover-photo.jpg";
      link.target = "_blank";
      // Append the element to the document
      document.body.appendChild(link);
      // Simulate a click on the element
      link.click();
      // Remove the element from the document
      document.body.removeChild(link);

      // await this.downloadImage(this.blogCoverPhoto);
    },

    formatNum(num) {
      // // If the number is 0, return empty string
      // if (num == 0){
      //   return '';
      // }
      // If the number is less than 1,000, return it as-is
      if (num < 1000) {
        return num;
      }

      // If the number is greater than 1,000, divide it by 1,000
      // and add the "k" abbreviation
      if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(1) + "k";
      }

      // If the number is greater than or equal to 1,000,000,
      // divide it by 1,000,000 and add the "m" abbreviation
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "m";
      }
    },

    getLikeCount() {
      const likeCountNum = this.artLikeCount;
      this.likeCount = this.formatNum(likeCountNum);
      return this.likeCount;
    },

    getDownloadCount() {
      const downloadCountNum = this.artDownloadCount;
      this.downloadCount = this.formatNum(downloadCountNum);
      return this.downloadCount;
    },

    deletePost() {
      this.$store.dispatch("deletePost", this.$store.state.blogID);
      // location.reload();
      this.deleteMsg = true;
      this.showDelete = false;
    },
    showDeletePopup() {
      this.showDelete = true;
    },
  },
  computed: {
    blogCoverPhoto() {
      return this.$store.state.blogPhotoFileURL;
    },
    blogTitle() {
      return this.$store.state.blogTitle;
    },
    blogHtml() {
      return this.$store.state.blogHTML;
    },
    blogUserName() {
      return this.$store.state.blogUserName;
    },
    artLikeCount() {
      return this.$store.state.artLikeCount;
    },
    artDownloadCount() {
      return this.$store.state.artDownloadCount;
    },
    artModel() {
      return this.$store.state.artModel;
    },
    showDeleteIcon() {
      if (this.$store.state.profileUserName == this.$store.state.blogUserName) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
  isCopiedVar(newValue) {
    if (newValue) {
      setTimeout(() => {
        this.isCopiedVar = false;
      }, 5000);
    }
  }
}
};
</script>

<style lang='scss' scoped>
hr {
  padding: 2%;
  border: none;
  border-top: 1px solid black;
  margin: 0;
}

.pressed-button {
  background-color: rgb(26, 25, 25) !important;
}

.art-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  padding: 0.3vw;
  background-color: #163856;
  margin-right: 1vw;
  // opacity: 80%;
  color: white;
  border-radius: 8px;

  .button-text {
    margin-right: 0.5vw;
    margin-left: 0.5vw;
    font-size: 14px;
  }

  .material-icons {
    font-size: 20px;
  }

  &:hover {
    background-color: black;
  }
}

.art-button-red {
  background-color: #c22c21;

  &:hover {
    background-color: #a0241c;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    .delete-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* Add the loading message */
  .loading-message {
    margin-left: 1rem;
    font-size: 1.5rem;
    // font-weight: bold;
    color: #f3f3f3;
  }

  /* Define the spinner animation */
  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .modal-content {
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 12px;
    height: calc(45vw);
    width: calc(60vw);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .share-art-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    height: calc(40vw);
    width: calc(60vw);
    margin: 0 auto;
    margin-top: 1vw;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.26),
      0 2px 4px -1px rgba(0, 0, 0, 0.26);
    // color: white;
    background-color: white;

    .art-prompt-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      //   justify-content: space-around;
      //   align-items: flex-start;
      padding: 10px;
      margin-left: 5px;
      width: calc(30vw);
      height: calc(40vw);

      .art-title-container {
        padding: 1vw;
        margin-bottom: 1vw;
        input:nth-child(1) {
          min-width: calc(26vw);
        }

        input {
          font-weight: bold;
          font-size: 1.4vw;
          transition: 0.2s ease-in-out all;
          padding: 10px 4px;
          border: none;
          border-bottom: 1px solid #163856;

          &:focus {
            outline: none;
            box-shadow: 0 1px 0 0 #163856;
          }
        }
      }

      .modal-prompt-container {
        padding: 15px;
        margin-top: 5px;
        border-radius: 12px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
          0 2px 4px -1px rgba(0, 0, 0, 0.36);
        height: calc(14vw);
        max-height: calc(15vw);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .prompt-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .art-copy-prompt-button {
            // padding: 1px;
            // margin-bottom: 20px;
            // float: right;

            .copy-button {
              display: inline-flex;
              align-items: center;
              justify-content: space-between;
              background-color: #163856;
              // opacity: 90%;
              color: white;
              text-align: center;
              padding: 6px;
              font-size: 12px;
              text-decoration: none;
              border-radius: 5px;

              .material-icons {
                font-size: 18px;
                margin-right: 5px;
              }

              &:hover {
                opacity: 100%;
                background-color: rgb(26, 25, 25);
                // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.5),
                //   0 2px 4px -1px rgba(0, 0, 0, 0.06);
              }
            }
          }
        }
        .prompt-box {
          width: 100%;
          height: calc(8vw);
          border-radius: 5px;
          border: 1px solid #ccc;
          outline: none;
          padding: 0.3vw;
          font-weight: bold;
          font-size: 1vw;
          font-family: "PT Mono", PT Mono, monospace;

          &:focus {
            outline: none;
            border: 2px solid #163856;
            // box-shadow: 0 1px 0 0 #163856;
          }
        }

        .prompt-box::-webkit-input-placeholder {
          color: black;
        }
        .prompt-box::-moz-placeholder {
          /* Firefox 19+ */
          color: black;
        }
        .prompt-box:-ms-input-placeholder {
          /* IE 10+ */
          color: black;
        }
        .prompt-box::-ms-input-placeholder {
          /* Edge */
          color: black;
        }

        h4 {
          font-family: "PT Mono", PT Mono, monospace;
          // white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 25vw;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }

      .modal-md-container {
        padding: 10px;
        margin-top: 10%;
        // border-radius: 12px;
        // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

        dt {
          opacity: 80%;
          font-size: calc(0.7vw);
          // font-style: italic;
        }

        dd {
          font-family: "PT Mono", PT Mono, monospace;
          font-weight: bold;
          font-size: calc(1vw);
        }

        .art-model-container {
          // padding: 1vw;
          // margin-bottom: 1vw;
          input:nth-child(1) {
            min-width: calc(10vw);
          }

          input {
            font-weight: bold;
            font-family: "PT Mono", PT Mono, monospace;
            font-size: 1vw;
            transition: 0.2s ease-in-out all;
            padding: 6px;
            border: none;
            border-bottom: 1px solid #163856;

            &:focus {
              outline: none;
              box-shadow: 0 1px 0 0 #163856;
            }
          }
        }
      }
      .dummy-container {
        height: calc(5vw);
      }
      .art-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 1vw;

        .art-md-buttons {
          display: flex;
          flex-direction: row-reverse;
        }
      }
    }

    .art-container {
      display: flex;
      margin: 0.8vw;
      border-radius: 12px;
      position: relative;
      height: calc(39vw);
      width: calc(30vw);
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.26);
      align-items: center;
      justify-content: center;

      .art-image {
        display: flex;
        position: absolute;
        align-content: center;
        padding: 0.5vw;
        // width: 100%;
        height: 100%;

        img {
          border-radius: 12px;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .upload-file {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        position: relative;
        align-content: center;
        padding: 10px;
        flex-direction: column;

        label {
          transition: 0.2s ease-in-out all;
          align-self: center;
          font-size: 14px;
          cursor: pointer;
          border-radius: 5px;
          padding: 12px 24px;
          color: #fff;
          background-color: #163856;
          text-decoration: none;
          font-weight: 600;

          &:hover {
            // background-color: rgba(22, 56, 86, 0.7);
            background-color: rgb(26, 25, 25);
          }
        }

        input {
          display: none;
        }
      }
    }
  }
}
</style>