import { render, staticRenderFns } from "./Blogs.vue?vue&type=template&id=817c15c4&scoped=true&"
import script from "./Blogs.vue?vue&type=script&lang=js&"
export * from "./Blogs.vue?vue&type=script&lang=js&"
import style0 from "./Blogs.vue?vue&type=style&index=0&id=817c15c4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817c15c4",
  null
  
)

export default component.exports